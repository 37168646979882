import axios from "axios";
import jwt_decode, { JwtPayload } from "jwt-decode";

const baseURL = process.env.OE_TX_API_URL;
const apiKey = process.env.OE_TX_API_KEY;

const apiInstance = axios.create({
  baseURL
});

// token which is used when calling a API, Used in authorization header
export const getToken = () => {
  if (typeof window !== "undefined") {
    if (window?.localStorage.getItem("token")) {
      return window?.localStorage.getItem("token");
    }
    return "";
  }
  return "";
};

function resetToken() {
  localStorage.removeItem("token");
}

// reset token when the page is refreshed
if (typeof window !== "undefined") {
  window?.addEventListener("beforeunload", resetToken);
}

async function fetchToken() {
  try {
    let url = typeof window !== "undefined" ? window?.location.search : "";
    const response = await axios.post(
      `${baseURL}/api/v1/enrollment/auth`,
      {
        application: "OE"
      },
      {
        headers: {
          "x-api-key": apiKey,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    );
    if (response?.data?.token) {
      localStorage.setItem("token", response.data.token);
    }
  } catch (error) {
    // console.error("Error fetching token:", error);
  }
}

const sleep = (time: number) => new Promise(r => setTimeout(r, time));

let authLoading = false;

apiInstance.interceptors.request.use(
  async req => {
    let refetch = false;
    let validToken = getToken();
    const token = validToken || "";

    if (token) {
      const exp = jwt_decode<JwtPayload>(token).exp;
      const now = new Date().getTime() / 1000;
      if (exp && now > exp) {
        refetch = true;
      } else {
        validToken = token;
      }
    } else {
      refetch = true;
    }
    if ((refetch || !token) && !authLoading) {
      authLoading = true;
      await fetchToken();
      validToken = getToken() ?? "";
      authLoading = false;
    } else if (authLoading) {
      await sleep(3000);
      validToken = getToken() ?? "";
    }
    req.headers["x-api-key"] = apiKey;
    req.headers["Authorization"] = `${validToken}`;
    return req;
  },
  error => {
    console.log("Axios ERROR REQUEST payload => ", JSON.stringify(error));
    return error;
  }
);

export default apiInstance;
