import React, {
  FC,
  createContext,
  useEffect,
  useContext,
  useState,
  ReactNode,
  useLayoutEffect
} from "react";
import { navigate } from "gatsby";
import { AddressType } from "../../util/types";
import DevelopmentApis, { setCookie } from "../../util/api/DevelopmentApi";
import { serializeAddress } from "../../features/zip-code-form/helpers";
import { getUrlWithExistingParams } from "../../util/utils/globalfunctions";
import LoadingComponent from "../loading";

interface ShopContextType {
  selectedAddress: AddressType | null;
  setSelectedAddress: (address: AddressType | null) => void;
  referralId: string;
  setReferralId: (referralId: string) => void;
  validateReferral: (referral: string) => Promise<boolean>;
  isServiceNotAvailable: boolean;
  setIsServiceNotAvailable: (serviceNotAvailable: boolean) => void;
  isInvalidReferral: boolean;
  setIsInvalidReferral: (isInvalidReferral) => void;
  isAddressLoading: boolean;
  setIsAddressLoading: (loading: boolean) => void;
  navigateToSelection: (address, zipCode, referralId) => void;
  fetchAddressByZip: (zip: string) => Promise<any>;
}
const Noop = () => {};

const ShopContext = createContext<ShopContextType>({
  selectedAddress: null,
  setSelectedAddress: Noop,
  referralId: "",
  setReferralId: Noop,
  isServiceNotAvailable: false,
  setIsServiceNotAvailable: Noop,
  validateReferral: async () => Promise.resolve(false),
  isInvalidReferral: false,
  setIsInvalidReferral: Noop,
  isAddressLoading: false,
  setIsAddressLoading: Noop,
  navigateToSelection: Noop,
  fetchAddressByZip: async () => Promise.resolve()
});

interface ShopProviderProps {
  children: ReactNode;
}

export const ShopProvider: FC<ShopProviderProps> = ({ children }) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressType | null>(
    null
  );
  const [referralId, setReferralId] = useState("");
  const [isServiceNotAvailable, setIsServiceNotAvailable] = useState(false);
  const [isInvalidReferral, setIsInvalidReferral] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(true);

  const urlSearchParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );
  const location = typeof window !== "undefined" ? window.location : {};
  const urlReferralId =
    urlSearchParams.get("referralID") ??
    urlSearchParams.get("txtReferralID") ??
    urlSearchParams.get("rafId");
  const urlZipCode =
    urlSearchParams.get("zipCode") ?? urlSearchParams.get("servicePostalCode");
  const urlLanguage = urlSearchParams.get("language");

  const navigateToSelection = (address, zipCode, referralId) => {
    const newParams: Record<string, string> = { zipCode };
    const removeParams = ["tdspCode", "servicePostalCode"];
    if (referralId) {
      newParams.referralID = referralId;
    } else {
      removeParams.push("referralID");
    }
    const queryParams = getUrlWithExistingParams(newParams, removeParams);
    const tdspArray = [].concat(address.tdsp).filter(Boolean);

    if (tdspArray.length === 0) {
      const newQueryParams = getUrlWithExistingParams({}, [
        ...removeParams,
        "zipCode"
      ]);
      // @ts-ignore
      navigate(`/search-for-plans${newQueryParams}`);
      setIsServiceNotAvailable(true);
    } else {
      if (tdspArray.length === 1) {
        // @ts-ignore
        navigate(`/offer-selection${queryParams}&tdspCode=${tdspArray[0]}`);
      } else {
        // @ts-ignore
        navigate(`/tdsp-selection${queryParams}`);
      }
    }
  };

  const navigateToZipPage = async (zipCode, referral, isValidReferral) => {
    const newParams: Record<string, string> = isValidReferral
      ? { zipCode }
      : {};
    if (referralId) {
      newParams.referralID = referral;
    }
    const removeParams = ["tdspCode", "servicePostalCode"];
    if (isValidReferral) {
      // service not available usecase
      removeParams.push("zipCode");
    }
    const queryParams = getUrlWithExistingParams(newParams, removeParams);
    // @ts-ignore
    navigate(`/search-for-plans${queryParams}`);
  };

  const fetchAddressByZip = async zipCode => {
    try {
      const result = await DevelopmentApis.searchServiceAddress(zipCode, "RES");
      if (result.data?.length > 0) {
        return result.data[0];
      } else {
        return null;
      }
    } catch (err) {
      console.error(`Failed to get address for zipCode ${zipCode}`);
    }
  };

  const validateReferral = async referral => {
    try {
      const result = await DevelopmentApis.validateReferralId(referral);
      const isValid = result.data.httpStatus === "OK";
      setIsInvalidReferral(!isValid);
      return isValid;
    } catch (err) {
      console.error(`Failed to validate referral ${referral}`);
      setIsInvalidReferral(true);
      return false;
    }
  };

  useEffect(() => {
    const initializeForm = async () => {
      setIsAddressLoading(true);
      try {
        let isValidReferral = true;
        if (urlReferralId) {
          setReferralId(urlReferralId);
          isValidReferral = await validateReferral(urlReferralId);
        }
        if (urlZipCode && !selectedAddress) {
          const addressResp = await fetchAddressByZip(urlZipCode);

          if (addressResp) {
            setSelectedAddress(serializeAddress(addressResp, false));
            setIsServiceNotAvailable(false);
            const isSearchForPlansPage = (
              location as Location
            ).pathname?.includes("search-for-plans");
            if (isValidReferral && isSearchForPlansPage) {
              await navigateToSelection(addressResp, urlZipCode, urlReferralId);
            }
          } else {
            setIsServiceNotAvailable(true);
            await navigateToZipPage(urlZipCode, urlReferralId, isValidReferral);
          }
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      } finally {
        setIsAddressLoading(false);
      }
    };

    initializeForm();
  }, [urlZipCode, urlReferralId]);

  useLayoutEffect(() => {
    if (urlLanguage) {
      // Set lang cookie if we get a language param
      setCookie(
        "language",
        urlLanguage.toLowerCase().includes("es") ? "ES" : "EN",
        90
      );
    }
  }, [urlLanguage]);

  return (
    <ShopContext.Provider
      value={{
        selectedAddress,
        setSelectedAddress,
        referralId,
        setReferralId,
        isServiceNotAvailable,
        setIsServiceNotAvailable,
        isInvalidReferral,
        setIsInvalidReferral,
        isAddressLoading,
        setIsAddressLoading,
        validateReferral,
        navigateToSelection,
        fetchAddressByZip
      }}
    >
      <LoadingComponent show={isAddressLoading} msg="" />
      {!isAddressLoading && children}
    </ShopContext.Provider>
  );
};

export const useShopContext = (): ShopContextType => {
  const context = useContext(ShopContext);

  if (context === undefined) {
    throw new Error("useShopContext must be used within an ShopProvider");
  }

  return context;
};
