import React, { useEffect, useState } from "react";
import "./index.scss";
import { RichTextBody, Modal } from "@nrg-storybook/reliant-component-library";
import loaderAnimation from "../../images/loaderAnimation.json";
import { useTranslation } from "react-i18next";

interface LoadingProps {
  msg?: string;
  show: any;
}

const LoadingComponent = ({ show, msg }: LoadingProps) => {
  const [dotLoader, setDotLoader] = useState<JSX.Element | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!dotLoader && show) {
      import("lottie-react").then(lr => {
        const Lottie = lr.default;
        setDotLoader(<Lottie animationData={loaderAnimation} loop={true} />);
      });
    }
  }, [dotLoader, show]);

  return show && dotLoader ? (
    <Modal
      show={show}
      showTitleBar={false}
      titleText=""
      modalType="dialog"
      modalClassName="loader-modal"
      hideCloseButton
      modalSize="Extra Large"
      showActionSlot={false}
      sizes="Small"
    >
      <div className="text-center loaderContent">
        <div className="loaderImg">{dotLoader}</div>
        <RichTextBody tag="p" text={t("productChart:loading.please_wait")} />
      </div>
    </Modal>
  ) : null;
};

export default LoadingComponent;
