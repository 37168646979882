import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { chatlaunch } from "@nrg-storybook/reliant-component-library/lib/scripts/chatlaunch";
import { readCookie } from "@nrg-storybook/reliant-component-library/lib/scripts/index";


const NiceChat = () => {
  const { i18n, t } = useTranslation();
  const chatChannel = process.env.CHATCHANNEL;
  const lang = i18n.language || readCookie("language");
  const chatLanguage = lang?.toLowerCase() === "es" ? "Spanish" : "English";
  const current_date = new Date(); // get current date/timestamp
  const hour = current_date.getUTCHours(); // get UTC Hours value since Date() objects in JS are UTC, and we want code to be consistent even for users in different timezones
  const day = current_date.getUTCDay(); // same as above, but get day of the week

  useEffect(() => {
    if (typeof window["cxone"] === "undefined") {
      if (day >= 1 && day <= 5 && hour >= 14 && hour <= 24) {
        chatlaunch({
          channel: chatChannel,
          language: chatLanguage,
          applaunchsource: "web",
          showTermsOfUse: t("productChart:chat.privacy_policy")
        });
      }
    } else {
      window.location.reload();
    }
  }, [chatLanguage]);
  return <></>;
};

export default NiceChat;
